<template>
    <div class="container-fluid">
        <Navbar active="cloud" :userCredentials="user.credentials" />
        <div class="overflow-gidden p-0 cover">
            <b-modal v-model="deleteMediaModal" hide-header hide-footer>
                <div class="row mt-4 text-center">
                    <div class="col-lg-12">
                        <h2 class="custom-color-secondary">
                            Supprimer un Media sur le cloud
                        </h2>
                    </div>
                </div>
                <div class="row mt-4 text-center">
                    <div class="col-lg-12">
                        <p>
                            Etes vous sur de vouloir supprimer le(s) media(s)
                            sélectionnée(s)
                        </p>
                    </div>
                </div>
                <div class="row mt-4 text-center">
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-secondary"
                            v-on:click="deleteMedia"
                            >Supprimer
                        </b-button>
                    </div>
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-primary"
                            v-on:click="deleteMediaOpen"
                            >Annuler
                        </b-button>
                    </div>
                </div>
            </b-modal>
            <b-modal v-model="createMediaModal" hide-header hide-footer>
                <div class="row mt-4 text-center">
                    <div class="col-lg-12">
                        <h2 class="custom-color-secondary">
                            Ajouter un Media sur le cloud
                        </h2>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <b-form-file
                            v-model="addMediaData.file"
                            :state="Boolean(addMediaData.file)"
                            placeholder="Choisir
                        le ficher ou le déposer ici"
                            drop-placeholder="Déposer
                        ici"
                        >
                        </b-form-file>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12 text-center">
                        <b-button
                            class="custom-btn-color-secondary"
                            v-on:click="createMedia"
                            >Ajouter
                            <b-spinner v-if="isLoading" small></b-spinner
                        ></b-button>
                    </div>
                </div>
            </b-modal>
            <div class="row">
                <div class="col-lg-9">
                    <table class="table table-striped custom-bordered">
                        <thead>
                            <tr>
                                <td scope="col">
                                    <label class="container text-center">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td class="custom-table-th">Numéro</td>
                                <td class="custom-table-th">Prévisu</td>
                                <td class="custom-table-th">Nom</td>
                                <td class="custom-table-th">Crééé le</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="media of medias" :key="media.uiCode">
                                <td class="align-middle">
                                    <label class="container">
                                        <input
                                            type="checkbox"
                                            v-model="checkTab[media.uiCode]"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td class="align-middle">{{ media.uiCode }}</td>
                                <td class="align-middle">
                                    {{ media.resourcesName }}
                                </td>
                                <td>
                                    <img
                                        :src="
                                            createUrl(
                                                media.advertisersCode,
                                                media.uiCode,
                                                media.resourcesName
                                            )
                                        "
                                    />
                                </td>
                                <td class="align-middle">
                                    {{ media.uplodDate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-3">
                    <b-card-group>
                        <b-card
                            header-class="custom-card-header"
                            class="custom-card-body"
                        >
                            <template #header> Opérations </template>
                            <b-card-body class="reduce-padding"
                                ><div class="row text-left">
                                    <div
                                        class="col-lg-12"
                                        v-on:click="createMediaOpen"
                                    >
                                        <span class="text-white pointer">
                                            Ajouter séq
                                        </span>
                                        <span class="text-danger">
                                            (upload à partir du serveur)
                                        </span>
                                    </div>
                                </div>
                                <div class="row text-left">
                                    <div
                                        class="col-lg-12 pointer"
                                        v-on:click="deleteMediaOpen"
                                    >
                                        <span class="text-white pointer">
                                            Effacer séq
                                        </span>
                                        <span class="text-danger">
                                            (séq séléctionnée)
                                        </span>
                                    </div>
                                </div>
                            </b-card-body></b-card
                        ></b-card-group
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <b-spinner
                        v-if="isLoading"
                        style="width: 3rem; height: 3rem"
                    ></b-spinner>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Navbar from "@/components/layout/NavbarClient.vue";
import Config from "@/config";

export default {
    data: function () {
        return {
            medias: [],
            user: {},
            createMediaModal: false,
            addMediaData: {},
            isLoading: false,
            fullPage: false,
            deleteMediaModal: false,
            checkTab: {},
            addMediaModal: false,
            config: "",
        };
    },
    components: {
        Navbar,
    },
    async beforeMount() {
        this.config = Config;
        this.user = this.$store.state.user.user;
        this.isLoading = true;
        this.medias = await this.$store.dispatch("media/getMedias", {
            groupId: 0,
        });
        this.medias = this.medias.data.aaData;
        this.isLoading = false;
    },
    methods: {
        createMedia: async function () {
            this.isLoading = true;
            await this.$store.dispatch("media/createMedia", {
                file: this.addMediaData.file,
                groupId: 0,
            });
            this.isLoading = false;
            // this.medias = await this.$store.dispatch("media/createMediaWRAP");
            this.createMediaOpen();
            this.isLoading = true;
            this.medias = await this.$store.dispatch("media/getMedias", {
                groupId: 0,
            });
            this.medias = this.medias.data.aaData;
            this.isLoading = false;
        },
        createMediaOpen: function () {
            this.createMediaModal = !this.createMediaModal;
        },
        deleteMediaOpen: function () {
            this.deleteMediaModal = !this.deleteMediaModal;
        },
        deleteMedia: async function () {
            let deleteArray = [];
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value == true) {
                    deleteArray.push(key);
                }
            }
            await this.$store.dispatch("media/deleteMedia", {
                uiCode: deleteArray.toString(),
                advertisersCode: this.user.userId,
            });
            this.deleteMediaOpen();
            this.isLoading = true;
            this.medias = await this.$store.dispatch("media/getMedias", {
                groupId: 0,
            });
            this.medias = this.medias.data.aaData;
            this.isLoading = false;
        },
        createUrl: function (advertisersCode, uiCode, resourcesName) {
            let jpgName = resourcesName.replace(".mp4", ".jpg");

            return (
                this.config.api +
                "/ui/" +
                advertisersCode +
                "/" +
                uiCode +
                "/" +
                jpgName
            );
        },
    },
};
</script>
<style>
.custom-card-header {
    color: #0e476b;
    background-color: white;
}
.custom-card-body {
    background-color: #11a9b9;
    border: 2px solid #11a9b9;
}
.reduce-padding {
    padding-top: 0;
    padding-bottom: 0;
}
</style>
